import { Injector } from '@angular/core';
import {
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NgControl,
  NgModel,
} from '@angular/forms';

export function getNgControl(injector: Injector) {
  const injectedControl = injector.get(NgControl, {
    Optional: true,
  });

  if (!injectedControl) return undefined;

  switch (injectedControl.constructor) {
    case NgModel: {
      const { control } = injectedControl as NgModel;

      return control;
    }
    case FormControlName: {
      return injector
        .get(FormGroupDirective)
        .getControl(injectedControl as FormControlName);
    }
    default: {
      return (injectedControl as FormControlDirective).form as FormControl;
    }
  }
}
